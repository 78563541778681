<template>
  <div class="btn-wrapper">
    <button class="custom-button" :class="staticSize">
      {{ message }}
    </button>
  </div>
</template>

<script>
export default {
  name: "CustomButton",
  data() {
    return {
      styleForButton: {
        sizeForButton: {
          sm: {
            height: "56px",
            width: "164px",
          },
          md: {
            height: "56px",
            width: "208px",
          },
          lg: {
            height: "56px",
            width: "308px",
          },
        },
      },
    };
  },
  props: {
    message: {
      type: String,
      default: "Send",
    },
    staticSize: {
      type: String,
      default: "sm",
    },
  },
};
</script>
