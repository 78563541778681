<template>
  <div class="failed-payment">
    <div class="wrapper">
      <h1>Thank you!</h1>
      <p>Your payment is successful.</p>
      <Button :message="'Try Again'"></Button>
    </div>
  </div>
</template>

<script>
import Button from "@/components/Button/CustomButton.vue";

export default {
  name: "FailedPayment",
  components: {
    Button,
  },
};
</script>
